const ContentMenu = (($) => {
  const init = () => {

    jQuery('.dwd-wp-block-content-menu').closest('.wp-block-group').attr('data-content-menu', '');

    jQuery(document).on('click', '[data-content-menu] a', function () {
      var that = this;

      jQuery('[data-content-menu] a').removeClass('js-active');

      setTimeout(function () {
        jQuery(that).addClass('js-active');
      }, 1000);
    });

    jQuery('[data-content-menu] a[href]').each(function () {
      if (jQuery(this).attr('href') == window.location.href) {
        jQuery(this).addClass('js-active');
      }
    });

    jQuery(window).on('scroll', function () {
      jQuery('[data-content-menu] a[href*="#"]').removeClass('js-active');
    });


    jQuery('.dwd-wp-list-menu').each(function() {
        jQuery(this).parent('.wp-block-column').addClass('dwd-content-menu__stick').parent().addClass('align-start');
    });

    if ( jQuery('.dwd-wp-list-menu').length >0 ) {

        jQuery('.dwd-wp-list-menu ul li').each(function(){
          var href = $(this).find('a').attr('href');

          if ( window.location.href == href ) {
            $(this).addClass('active');
          }
        });
    }

  };

  const always = () => {};

  return {
    init: init,
    always: always,
  };
})(jQuery);

export { ContentMenu };
