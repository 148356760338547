/* Equalizer
 * usage:
 * in html use 'data-equalizer' for parent and 'data-equalizer-watch' for children
 * ---------------------------------
 *
 *  js needed to use it:
 *

import { equalizer } from './helpers';

window.onload = () => {
  equalizer( document.querySelectorAll('[data-equalizer]') );
}

 * ---------------------------------
 */
export function equalizer(row) {
  for (let r of row) {
    const column = r.querySelectorAll('[data-equalizer-watch]');
    [].forEach.call(column, c => {
      c.style.height = 'auto';
    });
    const row_height = r.clientHeight;

    if (screensize() != 'small' && row_height > 0) {
      [].forEach.call(column, c => (c.style.height = row_height + 'px'));
    }
  }
}

/* equalHeights
 * usage:
 * in html use 'data-equalheights="X"' for every X you want to have to same height
 * ---------------------------------
 *
 *  js needed to use it:
 *

import { equalHeights } from './helpers';

window.onload = () => {
  equalHeights( document.querySelectorAll('[data-equalheights]') );
}

window.onresize = () => {
  equalHeights( document.querySelectorAll('[data-equalheights]') );
}

 * ---------------------------------
 */
export function equalHeights(panels) {
  let maxHeight = [];

  for (let p of panels) {
    //reset panel height
    p.style.height = 'auto';
    const identifiers = p.getAttribute('data-equalheights').split(',');
    let identifier = '';

    if (screensize() == 'large') {
      identifier = identifiers[0];
    } else if (screensize() == 'medium') {
      identifier = identifiers[1];
    } else {
      identifier = identifiers[2];
    }

    if (identifier) {
      maxHeight[identifier] =
        maxHeight[identifier] > p.clientHeight
          ? maxHeight[identifier]
          : p.clientHeight;
    }
  }

  [].forEach.call(panels, p => {
    const identifiers = p.getAttribute('data-equalheights').split(',');
    let identifier = '';

    if (screensize() == 'large') {
      identifier = identifiers[0];
    } else if (screensize() == 'medium') {
      identifier = identifiers[1];
    } else {
      identifier = identifiers[2];
    }

    p.style.height = maxHeight[identifier] + 'px';
  });
}

/* Breakpoints
 * usage in css: set breakpoint values (with foundation, or manually in _base.typography.scss)
 * usage in js: screensize()
 * ---------------------------------
 *
 *  js needed to use it:
 *

import { screensize } from './helpers';

if( screensize() == 'small' ) {
  //default
}elseif( screensize() == 'medium' ){
  //$medium-up
}elseif( screensize() == 'large' ){
  //$large-up
}

 * ---------------------------------
 */
export function screensize() {
  let breakpoint;

  screensize.getValue = () => {
    return window
      .getComputedStyle(document.querySelector('html'), ':before')
      .getPropertyValue('content')
      .replace(/\"/g, '');
  };

  breakpoint = screensize.getValue();
  return breakpoint;
}

/* addSymbolsFromSprite
 *
 * adds the generated sprite to your html to
 * allow linking to them with the_symbol() function
 *
 * ---------------------------------
 */
export function addSymbolsFromSprite(sprite_location) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', sprite_location, true);
  xhr.send();
  xhr.onload = function(e) {
    var svg = document.createElement('div');
    svg.setAttribute('class', 'o-svg-icon__sprite');
    svg.innerHTML = xhr.responseText;
    document.body.insertBefore(svg, document.body.childNodes[0]);
  };
}

/* datepickerConfig
 *
 * default config for gravityforms datepicker
 *
 * ---------------------------------
 */
export const datepickerConfig = {
  changeMonth: true,
  changeYear: true,
  suppressDatePicker: false,
  yearRange: '-100:+20',
  showOn: 'focus',
  closeText: 'Sluiten',
  currentText: 'Vandaag',
  monthNames: [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ],
  monthNamesShort: [
    'jan',
    'feb',
    'mrt',
    'apr',
    'mei',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec'
  ],
  nextText: 'Volgende',
  prevText: 'Vorige',
  dayNames: [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag'
  ],
  dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
  dayNamesMin: ['Z', 'm', 'D', 'w', 'd', 'v', 'Z'],
  dateFormat: 'd MM yy',
  firstDay: 1,
  isRTL: false
};

/* LazyLoad
 *
 * ---------------------------------
 */
/*
export function ivLazyLoadInit() {
  return new LazyLoad({
    elements_selector: '[data-lazy]',
    callback_load: function(el) {
      if (jQuery(el).parents('[data-equalizer]').length > 0) {
        equalizer([jQuery(el).parents('[data-equalizer]')[0]]);
      }
      if (jQuery(el).parents('[data-equalheights]').length > 0) {
        var attr = jQuery(el)
          .parents('[data-equalheights]')
          .data('equalheights');
        equalHeights(
          document.querySelectorAll('[data-equalheights="' + attr + '"])')
        );
      }
      if (jQuery(el).parents('.slick-initialized').length > 0) {
        jQuery(el)
          .parents('.slick-initialized')
          .slick('refresh');
      }
    }
  });
}
*/