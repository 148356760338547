import { equalHeights } from './helpers';

const Archive = ($ => {
  let load_more_btn, clicked, filter, xhr;

  const init = () => {
    load_more_btn = '[data-load-more]';
    filter = '[data-filter]';
    clicked = false;

    $(document).on('click', load_more_btn, e => {
      e.preventDefault();
      if (!clicked) {
        load_more_items($(e.target), 'append');
      }
    });

    $(document).on('click', filter, e => {
      e.preventDefault();
      if (!$(e.target).hasClass('active')) {
        $(filter).removeClass('active');
        $(e.target).addClass('active');
        load_more_items($(e.target), 'replace');
      }
    });
  };

  const load_more_items = (elem, action) => {
    clicked = true;
    const target = elem.data('type');
    const url = elem.attr('href');
    elem.attr('disabled', 'disabled').addClass('loading');

    if (xhr && xhr.readyState != 4) {
      xhr.abort();
    }
    xhr = $.get(url, function(data) {
      const $data = $(data);
      const items = $data.find(`[data-item="${target}"]`);
      const next_btn = $data.find(`[data-type="${target}"]${load_more_btn}`);

      console.log(next_btn);

      if (action == 'append') {
        items.appendTo(`[data-container="${target}"]`);
      } else {
        elem.removeAttr('disabled').removeClass('loading');
        $(`[data-container="${target}"]`).html(items);
      }

      console.log(`[data-type="${target}"]${load_more_btn}`);

      $(`[data-type="${target}"]${load_more_btn}`).replaceWith(next_btn);
      clicked = false;

      // load lazyload
      //ivLazyLoadInit();

      equalHeights(document.querySelectorAll('[data-equalheights]'));
    });
  };

  return {
    init: init
  };
})(jQuery);

export { Archive };
