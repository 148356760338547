const ContentMore = (($) => {
  const init = () => {
    jQuery('.dwd-content-more__toggle').parent('.wp-block-buttons').addClass('shadow');

    jQuery(document).on('click', '.dwd-content-more__toggle', function (e) {
      let parent = jQuery(this).parents('.wp-block-buttons').prev('.dwd-content-more__hidden');
      if (parent.length > 0) {
        jQuery(parent).toggleClass('open');

        if (jQuery(parent).hasClass('open')) {
          jQuery(this).find('.wp-block-button__link').text('Lees minder');
        } else {
          jQuery(this).find('.wp-block-button__link').text('Lees meer');
        }
      }
    });
  };
  return {
    init: init,
  };
})(jQuery);
export { ContentMore };
