import { screensize } from './helpers';

const Map = ($ => {
  let maps;

  const init = () => {
    maps = $('[data-map]');

    // loop each map and set markers
    if (maps.length > 0) {
      var mapOptions = {
        center: new google.maps.LatLng(51.811162, 4.323704),
        zoom: 20,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      maps.each(function() {
        let id = $(this).data('map');
        let map = new google.maps.Map(
          document.getElementById('map-' + id),
          mapOptions
        );

        // the dwd_ variable is set in class-map.php
        let map_vars = window[`dwd_map_${id}`];

        let locations = map_vars.locations;

        if (locations.length > 0) {
          setMarkers(map, locations);
        }
      });
    }
  };

  const getTitle = location => {
    if ('post' in location) {
      return location.post.post_title.toString();
    }
    if ('user' in location) {
      return location.name.toString();
    }
    return '';
  };

  const setMarkers = (map, locations) => {
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < locations.length; i++) {
      var location = locations[i];

      if (location.location.length != 0) {
        if (location.use_same_location_as == null || location.use_same_location_as == '') {
          var myLatLng = new google.maps.LatLng(
            location.location.lat,
            location.location.lng
          );

          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: getTitle(location),
            icon: {
              url: baseObj.theme_path + '/public/images/' + location.marker,
              size: new google.maps.Size(22, 28),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(11, 28)
            }
          });

          marker.setTitle(getTitle(location));

          attachMessage(marker, location);

          bounds.extend(myLatLng);
        }
      }
    }

    map.setCenter(bounds.getCenter());

    if (locations.length == 1) {
      map.setZoom(13);

      if (screensize() != 'small') {
        map.setCenter(
          new google.maps.LatLng(
            location.location.lat,
            parseFloat(location.location.lng) + 0.04
          )
        );
      }
    } else if (locations.length > 1) {
      map.fitBounds(bounds);
      map.panToBounds(bounds);
    }

    if (map.getZoom() < 13) {
      map.setZoom(13);
    }

    return true;
  };

  const attachMessage = (marker, location) => {
    var ibOptions = {
      LatLng: new google.maps.LatLng(51.93785, 4.41633),
      isHidden: false,
      pixelOffset: new google.maps.Size(-120, -40),
      infoBoxClearance: new google.maps.Size(10, 60),
      pane: 'floatPane',
      enableEventPropagation: false,
      alignBottom: true,
      content:
        '<div class="dwd-wp-locations__map-info">' + location.infobox + '</div>'
    };

    var infowindow = new InfoBox(ibOptions);

    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open(marker.get('map'), marker);
    });
  };

  return {
    init: init
  };
})(jQuery);

export { Map };
