
const Navigation = ($ => {
  const init = () => {
    const nav_item = jQuery('.nav .menu-item.menu-vacancy-count');

    if (
        nav_item.length
        && typeof baseObj !== 'undefined'
        && typeof baseObj.vacancy_count !== 'undefined'
        && !nav_item.find('.menu-vacancy-count__label').length
    ) {
        nav_item.find('> a').append('<div class="menu-vacancy-count__label">' + baseObj.vacancy_count + '</div>');
    }
  };

  return {
    init: init
  };
})(jQuery);

export { Navigation };
